import React, { FC } from 'react'
import styled from 'styled-components'
import { InfoSecondIcon } from '../../components/Icon/icons/InfoSecond.icon'
import { MoneyShieldIcon } from '../../components/Icon/icons/MoneyShield.icon'
import { QuestionIcon } from '../../components/Icon/icons/Question.icon'
import { VerifiedIcon } from '../../components/Icon/icons/Verified.icon'
import { WarningIcon } from '../../components/Icon/icons/Warning.icon'
import { atMinWidth } from '../../styles/helpers'
import {
  InstructionContent,
  QuestionContent,
  QuestionTitle,
  QuestionWrapper,
  StyledLink
} from './styles'

export const GetCardInstructions: FC = () => {
  return <InstructionWrapper>
    <InfoClaimerWrapper>
      <div>
        <InfoSecondIcon />
      </div>
      <InfoClaimer><b>Do not save the card and always use this form to reveal the card number.</b> This payment method is limited to <b>5000 CZK</b> per payment and should be used <b>only for one time purchases</b>.
        For purchases that exceed this limit/are recurring (licences, services, ...), make a request in <StyledLink target="_blank" href={'https://www.notion.so/applifting/73fe21421d204821b4578fae6e497c3a'}>Financial planner.</StyledLink></InfoClaimer>
    </InfoClaimerWrapper>

    <InfoClaimerWrapper>
      <div>
        <InfoSecondIcon />
      </div>
      <InfoClaimer>After you pay for something, a message should appear in{' '}
        <BoldLink href="https://applifting.slack.com/channels/revolut" target="_blank" rel="noopener noreferrer">
          #revolut
        </BoldLink>{' '} channel.
        Please claim your payment ownership by 👋 emoji and
        <StyledUl>
          <li>mention the payment purpose,</li>
          <li>tag the person you consulted with,</li>
          <li>specify if it is billable to a client or used for a specific project and</li>
          <li>attach an invoice.</li>
          <li>If you used the revolut@applifting.cz as a billing address recipient, mention it there, so we know where to look.</li>
          <li>If you'll receive the invoice later, please provide an estimated time.</li>
        </StyledUl>
      </InfoClaimer>
    </InfoClaimerWrapper>

    <InstructionsWrapper>
      <InstructionPart>
        <VerifiedIcon />
        <InstructionTitle>Verification</InstructionTitle>
        <InstructionContent>Some payment providers require payment to be verified by second factor - if this is the case, contact Cardholder
          Ondřej Kapal
          by slack or phone and he'll confirm the payment.
          <StyledUl>
            <li>
              If you can’t reach out, you can contact
              Martin Balák [MBA]
              to resolve your payment.
            </li>
          </StyledUl>
        </InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <MoneyShieldIcon />
        <InstructionTitle>Security</InstructionTitle>
        <InstructionContent>Having an open way how to pay is important for the independence, but also brings a big chunk of responsibility. Please be reasonable when manipulating with shared cards.
          <StyledUl>
            <li>The card will be reset once in a while to reduce a chance that a leaked card will be misused.</li>
          </StyledUl>
        </InstructionContent>
      </InstructionPart>

      <InstructionPart>
        <WarningIcon />
        <InstructionTitle>Security incidents</InstructionTitle>
        <InstructionContent>
          If you think the card info was stolen, do not hesitate and contact:
          <StyledUl>
            <li>Martin Balák (<StyledLink href="tel:00420776010462">+420776010462</StyledLink>)</li>
            <li>Filip Kirschner (<StyledLink href="tel:00420739627797">+420 739 627 797</StyledLink>)</li>
            <li><StyledLink href="mailto:security@applifting.cz">security@applifting.cz</StyledLink></li>
          </StyledUl>
        </InstructionContent>
      </InstructionPart>


    </InstructionsWrapper>
    <QuestionWrapper>
      <QuestionTitle><QuestionIcon /> All invoices MUST include our invoicing address including VAT number: CZ02542072</QuestionTitle>
      <QuestionContent>
        <p>
          Applifting s.r.o. <br/>
          IČ: 02542072 <br/>
          DIČ: CZ02542072 <br/>
          Rohanské nábřeží 670/19 <br/>
          Praha 8<br/>
          186 00
        </p>
        If you’re a contractor, please avoid using your First and Last name in the billing details. Use Filip Kirschner or Vratislav Kalenda instead.
        If possible, use revolut@applifting.cz as a billing email recipient and let us know in #revolut channel as described <StyledLink href={'https://www.notion.so/applifting/5d6d43a8b7994a51b6e3d6d4286e9056'} target='_blank'>here</StyledLink>.
      </QuestionContent>
    </QuestionWrapper>

    Having troubles?
    <p>If you are still not sure about payment, please read the <StyledLink href="https://www.notion.so/applifting/Decision-making-The-advice-process-8edbe3a10d744006945a75b437d9aef5">Decision making - The advice process</StyledLink> or contact our BO. </p>
  </InstructionWrapper>
}

const InfoClaimerWrapper = styled.div`
  border: 1px solid #FFB545;
  display: flex;
  margin-bottom: 32px;
  padding: 10px;

  ${atMinWidth.tablet} {
    padding: 20px;
  }
`

const InfoClaimer = styled.div`
  padding-left: 20px;
`

const InstructionWrapper = styled.div`
  margin-top: 32px;
`

const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;

  ${atMinWidth.desktopLarge} {
    column-gap: 40px;
    display: grid;
    grid-template-columns: auto auto auto;
  }
`

const InstructionPart = styled.div`
  svg {
    margin-bottom: 12px;
  }
`
const InstructionTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  margin-bottom: 8px;
`

const BoldLink = styled.a`
  font-weight: bold;
  font-family: Avenir;
`

const StyledUl = styled.ul`
  margin-left: 2rem;
    margin-top: 0.5rem;
`

