import styled from 'styled-components'
import { atMinWidth } from '../../styles/helpers'

export const InstructionContent = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;

  ${atMinWidth.desktop} {
    margin-bottom: 32px;
  }

  b {
    font-weight: 700;
  }
`

export const InstructionTwoRowsContent = styled(InstructionContent)`
  margin-bottom: 8px;
`

export const StyledLink = styled.a`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`

export const QuestionWrapper = styled.div`
  background: #F0F0F0;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 17px;
`

export const QuestionTitle = styled.div`
  align-items: center;
  display: flex;
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 18px;
  margin-bottom: 12px;

  svg {
    margin-right: 16px;
  }
`

export const QuestionContent = styled.p`
  font-size: 1rem;
  margin: 0;
`
